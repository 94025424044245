import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 748.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,748.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M5155 6295 c-11 -2 -114 -9 -230 -16 -115 -7 -219 -16 -230 -20 -11
-4 116 -8 281 -8 259 -1 303 1 314 14 7 9 10 18 6 22 -7 8 -110 13 -141 8z"/>
<path d="M5475 6269 c61 -4 291 -15 512 -24 281 -11 413 -20 441 -29 53 -19
260 -59 275 -53 17 7 -29 23 -162 58 l-116 31 -455 13 c-250 8 -489 13 -530
13 -68 -1 -65 -2 35 -9z"/>
<path d="M5987 6158 c-21 -11 -37 -21 -35 -23 2 -1 41 -5 88 -8 69 -5 79 -4
53 5 -62 21 -37 31 60 25 97 -5 149 5 80 16 -79 14 -206 5 -246 -15z"/>
<path d="M5928 6113 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6040 6100 c17 -9 223 -31 280 -29 27 1 20 4 -30 14 -82 16 -271 28
-250 15z"/>
<path d="M6320 5988 c-63 -9 -78 -14 -69 -22 6 -6 168 14 193 24 28 12 -37 11
-124 -2z"/>
<path d="M6150 5970 c8 -5 26 -10 40 -10 20 0 22 2 10 10 -8 5 -26 10 -40 10
-20 0 -22 -2 -10 -10z"/>
<path d="M6676 5964 c-8 -21 -1 -28 18 -21 9 4 13 13 10 22 -8 20 -20 19 -28
-1z"/>
<path d="M4250 5964 c0 -16 73 -134 83 -134 21 0 82 113 72 130 -7 11 -155 14
-155 4z"/>
<path d="M4482 5944 c9 -9 21 -13 27 -10 20 13 11 26 -16 26 -25 0 -26 -1 -11
-16z"/>
<path d="M6713 5913 c-7 -3 -13 -16 -13 -31 0 -30 45 -177 72 -238 20 -45 23
-80 8 -89 -5 -3 -10 -17 -10 -31 0 -13 -5 -24 -11 -24 -7 0 -18 -8 -25 -17
-12 -16 -13 -11 -14 37 0 30 7 68 15 84 19 37 19 48 -3 67 -26 22 -92 32 -92
14 0 -8 -6 -12 -14 -9 -20 8 -26 -20 -28 -112 -1 -44 -6 -82 -12 -86 -7 -5 -4
-8 7 -8 10 0 25 -7 34 -15 14 -14 13 -15 -13 -10 l-29 6 28 -21 c15 -12 24
-26 21 -31 -10 -16 39 -49 64 -44 13 3 21 2 18 -3 -3 -6 1 -15 8 -21 16 -13
14 -16 36 74 7 28 14 57 18 65 5 16 8 27 10 47 1 6 9 19 18 28 9 9 20 32 24
52 9 37 8 269 0 276 -16 12 -107 43 -117 40z"/>
<path d="M3785 5844 c-49 -13 -94 -29 -99 -34 -6 -6 -18 -10 -28 -10 -10 0
-18 -4 -18 -10 0 -13 37 -13 42 -1 2 6 35 -20 73 -55 39 -36 65 -63 58 -60 -6
4 -25 -2 -41 -11 -59 -36 -118 -78 -147 -104 -16 -14 -50 -37 -75 -50 -25 -13
-56 -33 -70 -45 -29 -25 -102 -38 -119 -21 -9 9 -13 6 -17 -10 -5 -19 -45 -40
-67 -34 -5 1 -7 -4 -4 -11 2 -8 18 -12 41 -10 31 2 38 -1 48 -23 11 -24 13
-25 24 -10 7 10 18 14 25 9 9 -5 7 -10 -6 -20 -10 -7 -15 -20 -12 -28 5 -12
16 -11 74 10 111 40 204 99 241 152 18 26 55 72 82 102 l49 55 62 -33 61 -33
-6 48 c-12 83 -17 103 -30 103 -19 0 -79 67 -72 79 9 15 39 14 54 -1 16 -16
16 4 0 49 -14 38 -7 38 -123 7z m-389 -461 c-10 -10 -19 5 -10 18 6 11 8 11
12 0 2 -7 1 -15 -2 -18z"/>
<path d="M5692 5840 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M7387 5819 c6 -8 43 -36 82 -64 39 -27 169 -121 289 -208 259 -189
362 -260 368 -254 5 5 -416 323 -521 394 -104 70 -230 147 -218 132z"/>
<path d="M3329 5762 c-463 -308 -625 -422 -601 -422 18 0 29 7 592 402 130 91
136 105 9 20z"/>
<path d="M7091 5787 c-6 -8 -8 -22 -5 -32 6 -14 3 -16 -14 -11 -11 4 -23 2
-27 -4 -4 -6 5 -10 22 -11 69 -3 87 8 73 45 -9 26 -33 32 -49 13z"/>
<path d="M7310 5781 c0 -6 5 -13 10 -16 21 -13 9 -23 -22 -20 -18 2 -28 1 -21
-2 15 -6 5 -33 -12 -33 -7 0 -15 -5 -17 -11 -2 -7 -13 -9 -27 -4 -14 4 -20 12
-17 21 3 7 -3 18 -14 24 -25 14 -30 13 -24 -5 4 -11 -4 -16 -34 -21 -28 -4
-42 -12 -45 -25 -3 -11 0 -19 7 -19 7 0 23 -11 36 -25 25 -26 92 -42 135 -31
21 5 25 12 25 40 0 78 31 87 91 27 38 -37 68 -39 35 -2 -9 10 -14 25 -11 33 4
9 -10 26 -39 46 -47 34 -56 38 -56 23z"/>
<path d="M3551 5771 c-10 -6 -10 -10 -1 -16 13 -8 24 -1 19 14 -3 6 -10 7 -18
2z"/>
<path d="M6944 5755 c-4 -11 -9 -23 -11 -27 -2 -5 2 -8 10 -8 19 0 28 23 17
41 -7 11 -10 9 -16 -6z"/>
<path d="M3582 5723 c2 -19 9 -27 26 -28 13 -1 25 2 28 7 3 4 -2 8 -11 8 -9 0
-19 9 -22 20 -8 30 -25 24 -21 -7z"/>
<path d="M3458 5717 c-15 -19 -17 -19 -18 -4 0 23 -30 22 -30 -2 0 -10 -7 -24
-15 -31 -8 -7 -15 -19 -15 -26 0 -8 -4 -14 -10 -14 -5 0 -10 -4 -10 -10 0 -5
7 -10 16 -10 9 0 23 -8 30 -17 13 -17 13 -17 14 0 1 53 55 107 88 88 9 -6 11
-11 5 -11 -22 0 -14 -20 7 -20 11 0 20 -4 20 -9 0 -5 9 -7 20 -4 30 8 25 18
-19 38 -22 9 -41 26 -44 36 -7 25 -18 23 -39 -4z"/>
<path d="M6860 5709 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14 -5
0 -10 -9 -10 -21z"/>
<path d="M4812 5660 c-12 -12 -22 -26 -22 -32 0 -11 114 -136 138 -151 23 -15
25 1 7 50 -21 53 -81 153 -93 153 -5 0 -18 -9 -30 -20z"/>
<path d="M6990 5670 c-13 -9 -13 -11 0 -20 8 -5 29 -10 45 -10 l30 1 -24 19
c-26 23 -30 23 -51 10z"/>
<path d="M5906 5645 c-4 -15 0 -38 10 -56 l16 -31 22 21 c31 29 39 26 32 -11
-3 -18 -2 -39 3 -47 13 -20 36 0 62 53 11 23 14 33 6 24 -20 -25 -30 -22 -17
3 12 22 10 29 -12 33 -7 2 -30 10 -50 20 -51 22 -65 20 -72 -9z"/>
<path d="M6890 5642 c-20 -7 -49 -44 -50 -62 0 -9 15 0 37 22 37 37 41 50 13
40z"/>
<path d="M6940 5635 c-19 -23 111 -171 186 -211 88 -47 152 -56 112 -16 -9 9
-9 12 0 12 7 0 12 -4 12 -9 0 -13 71 -91 83 -91 5 0 16 8 23 18 12 15 15 15
23 2 6 -8 14 -11 18 -6 4 5 2 11 -4 13 -7 3 -13 10 -13 18 0 14 -69 73 -124
105 -20 12 -36 28 -36 36 0 8 -9 14 -19 14 -11 0 -22 4 -25 8 -2 4 11 6 30 4
26 -3 39 1 49 14 7 10 11 22 9 25 -5 9 -97 -1 -108 -12 -5 -4 -27 1 -49 12
-21 11 -49 22 -60 25 -11 2 -32 15 -47 29 -30 29 -43 31 -60 10z"/>
<path d="M7330 5611 c0 -14 6 -17 25 -13 24 4 24 5 8 18 -25 18 -33 17 -33 -5z"/>
<path d="M3165 5574 c-53 -48 -45 -48 23 1 39 28 52 45 34 45 -4 0 -30 -21
-57 -46z"/>
<path d="M3445 5610 c-7 -11 3 -16 58 -25 37 -6 48 -19 25 -28 -10 -4 -10 -6
-1 -6 7 -1 22 9 33 22 l21 22 -53 5 c-29 3 -58 9 -65 13 -6 4 -14 3 -18 -3z"/>
<path d="M6066 5547 c-14 -29 -26 -61 -26 -70 0 -9 -5 -19 -10 -22 -15 -9 -2
-25 19 -25 11 0 24 12 31 30 20 48 34 49 27 2 -7 -41 -1 -72 13 -72 4 0 22 39
40 86 28 72 31 87 19 95 -25 16 -37 10 -49 -26 -12 -35 -30 -48 -30 -21 0 8
-5 16 -11 18 -9 3 -9 10 0 27 7 12 10 25 8 28 -3 3 -17 -20 -31 -50z"/>
<path d="M7450 5586 c0 -8 4 -17 9 -20 5 -3 11 -17 13 -30 6 -31 -19 -44 -37
-20 -9 13 -14 14 -19 5 -4 -6 -2 -18 4 -26 6 -8 9 -23 6 -33 -5 -14 5 -31 35
-61 23 -23 44 -40 46 -37 8 8 -10 58 -23 63 -8 3 -11 9 -8 14 3 5 -2 9 -10 9
-29 0 -27 20 3 26 27 6 31 11 31 37 0 44 14 22 30 -45 14 -60 30 -77 30 -32 0
23 2 25 15 14 32 -26 26 1 -9 43 -20 25 -35 51 -33 58 2 8 2 11 -1 6 -2 -4
-17 4 -34 18 -34 28 -48 32 -48 11z"/>
<path d="M3325 5580 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M6892 5578 c2 -6 10 -14 16 -16 7 -2 10 2 6 12 -7 18 -28 22 -22 4z"/>
<path d="M3437 5561 c-17 -6 -17 -8 5 -18 25 -11 68 -1 68 17 0 11 -45 12 -73
1z"/>
<path d="M3855 5550 c-24 -27 -16 -33 13 -10 12 10 18 21 14 24 -5 3 -17 -3
-27 -14z"/>
<path d="M3336 5515 c8 -37 9 -38 40 -42 16 -3 27 2 31 13 7 19 -15 36 -40 30
-9 -2 -22 3 -27 12 -8 13 -9 10 -4 -13z"/>
<path d="M7658 5497 c23 -23 42 -47 42 -53 0 -5 10 -14 22 -19 28 -13 48 -46
31 -52 -19 -7 -16 -22 5 -22 9 1 27 -2 38 -6 57 -18 10 80 -59 124 -17 11 -48
32 -67 45 -54 38 -59 31 -12 -17z"/>
<path d="M3883 5459 c-59 -58 -75 -70 -87 -60 -15 13 -62 5 -72 -11 -4 -6 0
-14 7 -19 10 -6 2 -9 -23 -9 -23 0 -56 -11 -88 -30 -28 -16 -55 -30 -60 -30
-6 0 -18 -7 -27 -15 -10 -8 -27 -21 -38 -29 -29 -21 -11 -63 43 -101 l44 -30
49 55 c26 30 49 59 49 65 0 5 -5 4 -12 -3 -20 -20 -28 -13 -16 13 14 31 34 32
49 3 7 -13 12 -24 11 -25 -1 -2 -2 -5 -2 -8 0 -12 50 43 128 140 44 55 92 111
106 124 26 24 33 41 19 41 -5 0 -41 -32 -80 -71z"/>
<path d="M4403 5508 c-55 -44 -92 -68 -107 -68 -8 0 -18 -4 -21 -10 -10 -16
12 -12 57 10 75 36 82 24 48 -75 -23 -67 -19 -85 6 -31 11 22 25 36 36 36 19
0 24 15 8 25 -5 3 -10 10 -10 16 0 16 26 10 44 -10 10 -10 26 -21 36 -24 16
-4 18 -2 10 13 -5 10 -23 29 -40 43 -26 23 -29 29 -20 52 18 48 -3 58 -47 23z"/>
<path d="M6808 5506 c-9 -22 -6 -30 34 -78 25 -29 52 -64 59 -78 24 -44 126
-184 152 -208 l25 -23 33 56 c74 122 80 135 74 144 -5 9 -39 30 -215 129 -25
15 -63 39 -84 54 -48 34 -63 35 -78 4z"/>
<path d="M6265 5482 c-7 -42 10 -126 24 -118 15 9 20 76 9 109 -17 50 -25 52
-33 9z"/>
<path d="M7330 5470 c8 -5 24 -10 35 -10 17 0 17 2 5 10 -8 5 -24 10 -35 10
-17 0 -17 -2 -5 -10z"/>
<path d="M3250 5445 c-7 -9 -8 -15 -2 -15 5 0 12 7 16 15 3 8 4 15 2 15 -2 0
-9 -7 -16 -15z"/>
<path d="M5865 5433 c14 -14 85 -36 92 -29 4 3 -1 6 -9 6 -9 0 -18 4 -20 10
-1 5 -19 12 -38 16 -24 5 -32 3 -25 -3z"/>
<path d="M2987 5377 c-44 -38 -46 -47 -12 -47 19 0 28 8 40 37 8 20 14 38 12
39 -2 2 -20 -11 -40 -29z"/>
<path d="M6608 5403 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7390 5387 c0 -17 20 -37 37 -37 10 0 10 6 -3 25 -18 27 -34 33 -34
12z"/>
<path d="M7550 5385 c0 -8 7 -15 16 -15 14 0 14 3 4 15 -7 8 -14 15 -16 15 -2
0 -4 -7 -4 -15z"/>
<path d="M2600 5359 c-22 -18 -22 -19 -3 -10 12 6 25 16 28 21 9 15 3 12 -25
-11z"/>
<path d="M3205 5360 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M7707 5353 c-4 -3 -7 -13 -7 -22 1 -13 3 -13 11 2 11 19 8 33 -4 20z"/>
<path d="M3219 5345 c-1 -3 -2 -8 -3 -12 -1 -5 -9 -24 -18 -43 -31 -69 -72
-230 -60 -242 13 -13 32 -2 32 18 0 10 7 13 26 8 16 -4 37 0 59 12 40 21 60
10 28 -16 -13 -10 -23 -23 -23 -30 0 -6 -4 -8 -10 -5 -5 3 -11 3 -11 -2 -8
-58 -8 -60 27 -76 19 -10 39 -17 45 -17 9 0 279 159 279 165 0 1 -27 19 -59
39 -84 51 -238 72 -262 34 -4 -6 -15 -5 -27 3 -12 7 -25 10 -29 6 -7 -7 5 -19
32 -31 13 -6 -24 -36 -46 -36 -18 0 -16 18 16 142 13 49 21 65 31 61 10 -3 12
-13 8 -26 -5 -16 -3 -19 11 -14 9 4 19 7 21 7 2 0 4 6 4 14 0 17 -68 56 -71
41z"/>
<path d="M7520 5344 c0 -26 33 -104 45 -104 7 0 20 -3 29 -6 11 -4 16 0 16 13
0 21 -29 53 -48 53 -7 0 -12 4 -12 8 0 13 -29 48 -30 36z"/>
<path d="M2493 5293 c-66 -40 -73 -48 -73 -76 l1 -32 14 25 c7 14 28 37 46 50
39 30 96 80 89 79 -3 -1 -38 -21 -77 -46z"/>
<path d="M4550 5330 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6100 5330 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6240 5320 c-8 -5 -12 -11 -9 -14 2 -3 11 -1 19 4 8 5 12 11 9 14 -2
3 -11 1 -19 -4z"/>
<path d="M7620 5327 c0 -5 5 -29 11 -55 12 -50 29 -78 29 -49 0 14 4 16 19 7
36 -19 29 4 -15 55 -24 27 -44 46 -44 42z"/>
<path d="M6340 5304 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M4560 5296 c0 -8 7 -16 15 -20 17 -6 18 -4 9 18 -8 20 -24 21 -24 2z"/>
<path d="M5045 5300 c-4 -7 0 -19 9 -28 13 -14 16 -14 16 -2 0 25 -16 44 -25
30z"/>
<path d="M6200 5300 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7286 5303 c-12 -12 -5 -23 14 -23 11 0 20 4 20 9 0 11 -26 22 -34
14z"/>
<path d="M3070 5293 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M4357 5249 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M4582 5240 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4610 5250 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M7382 5230 c-12 -16 -22 -33 -22 -39 0 -11 -25 -21 -54 -21 -12 0
-55 -20 -95 -45 -40 -25 -76 -45 -80 -45 -4 0 -37 16 -74 36 -37 20 -74 33
-82 30 -35 -13 1 -146 38 -146 14 0 125 44 134 54 13 12 34 5 89 -30 56 -36
56 -36 51 -78 -3 -22 -2 -59 2 -81 8 -40 8 -40 16 -12 4 16 5 50 2 77 l-6 49
28 -10 c16 -6 38 -20 50 -33 19 -21 21 -28 12 -67 -13 -55 -13 -59 -1 -59 6 0
10 14 10 30 0 17 5 30 11 30 7 0 9 -10 5 -25 -4 -14 -9 -45 -12 -69 l-6 -43
59 8 c62 9 153 42 153 56 0 5 47 7 105 5 80 -3 113 -1 138 11 17 8 77 34 132
57 54 22 108 50 119 62 19 20 19 22 3 50 -9 16 -17 35 -17 41 0 15 -40 81 -45
75 -2 -2 2 -28 10 -58 8 -30 15 -60 15 -66 0 -21 -210 -144 -246 -144 -22 0
-194 94 -194 106 0 17 -14 18 -41 4 -28 -15 -23 -38 9 -42 15 -2 26 -7 25 -13
-3 -11 -6 -12 -68 -14 -31 -1 -45 -5 -45 -15 0 -7 -12 -22 -27 -31 -16 -10
-36 -27 -46 -39 -18 -20 -18 -20 -11 -1 4 11 7 29 6 40 0 11 2 35 4 53 4 27 8
32 25 27 13 -4 19 -2 17 7 -2 8 9 14 27 16 17 2 33 10 38 17 4 7 25 16 48 20
40 7 41 8 34 39 -15 66 -24 102 -31 124 -6 21 -4 22 20 16 30 -7 32 -2 10 32
-11 16 -23 23 -32 19 -9 -3 -19 0 -24 7 -9 12 -7 -9 8 -112 4 -30 2 -40 -8
-40 -9 0 -21 26 -33 71 l-18 71 -63 -5 c-72 -5 -85 6 -53 47 11 14 16 26 12
26 -5 0 -19 -13 -31 -30z m218 -231 c0 -5 -7 -9 -15 -9 -8 0 -15 7 -15 15 0 9
6 12 15 9 8 -4 15 -10 15 -15z m-60 -189 c0 -10 -52 -40 -70 -40 -21 0 -9 17
23 33 38 19 47 20 47 7z"/>
<path d="M7920 5250 c0 -5 4 -10 9 -10 5 0 18 -3 28 -6 16 -5 16 -4 -1 10 -23
18 -36 20 -36 6z"/>
<path d="M6578 5245 c-3 -3 -2 -24 2 -47 4 -22 5 -53 1 -67 -9 -32 -6 -118 8
-235 21 -175 36 -101 27 131 -4 95 -5 177 -1 182 3 5 28 3 58 -5 61 -16 67
-17 67 -6 0 7 -80 35 -107 37 -6 0 -15 4 -19 8 -9 8 -28 9 -36 2z"/>
<path d="M7782 5239 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
<path d="M7312 5228 c3 -7 13 -15 22 -16 12 -3 17 1 14 10 -3 7 -13 15 -22 16
-12 3 -17 -1 -14 -10z"/>
<path d="M3225 5210 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10 -11
0 -17 -4 -14 -10z"/>
<path d="M7940 5202 c6 -13 14 -21 18 -18 3 4 -2 14 -12 24 -18 16 -18 16 -6
-6z"/>
<path d="M3744 5185 c-3 -13 -13 -27 -22 -30 -18 -6 -82 -61 -82 -70 0 -3 16
-5 36 -5 31 0 44 8 80 47 41 44 43 48 27 65 -22 24 -31 23 -39 -7z"/>
<path d="M3060 5190 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z"/>
<path d="M7744 5141 c0 -27 -6 -56 -12 -64 -15 -18 -6 -44 20 -58 16 -9 18
-17 13 -49 -8 -49 5 -57 45 -24 73 62 87 81 85 118 -1 47 -17 61 -70 61 -25 0
-45 5 -45 10 0 6 -8 20 -18 32 -17 23 -17 22 -18 -26z"/>
<path d="M2893 5173 c-15 -6 -17 -33 -3 -33 6 0 10 6 10 14 0 8 6 17 13 19 6
3 8 5 2 5 -5 0 -16 -2 -22 -5z"/>
<path d="M4315 5170 c-3 -5 3 -10 15 -10 12 0 18 5 15 10 -3 6 -10 10 -15 10
-5 0 -12 -4 -15 -10z"/>
<path d="M7676 5171 c-7 -11 3 -41 14 -41 4 0 7 11 6 25 -1 25 -10 32 -20 16z"/>
<path d="M6720 5058 l0 -43 25 26 c29 28 25 59 -6 59 -16 0 -19 -7 -19 -42z"/>
<path d="M7944 5074 c-4 -16 -11 -35 -17 -41 -5 -7 -6 -13 -1 -13 16 0 46 62
35 73 -7 7 -12 0 -17 -19z"/>
<path d="M3555 5059 c-42 -31 -65 -57 -65 -71 0 -11 23 5 85 60 55 48 40 57
-20 11z"/>
<path d="M4252 5026 c-10 -17 -28 -59 -39 -93 l-21 -61 61 -11 c33 -6 63 -14
66 -18 10 -13 52 -14 88 -2 18 6 47 26 65 45 18 19 40 34 50 34 14 0 18 8 18
36 0 19 -5 44 -10 55 -10 18 -12 17 -52 -11 -22 -17 -45 -30 -51 -30 -5 0 -43
20 -83 44 l-73 44 -19 -32z"/>
<path d="M3817 5033 c-14 -14 -6 -21 32 -27 28 -4 42 -12 46 -26 7 -22 104
-104 113 -96 9 10 -111 131 -142 143 -38 14 -41 15 -49 6z"/>
<path d="M3627 4983 c-26 -26 -47 -51 -47 -55 0 -15 85 38 95 60 5 12 13 22
17 22 5 0 8 5 8 10 0 22 -30 7 -73 -37z"/>
<path d="M2396 4970 c-8 -61 -12 -55 77 -109 37 -22 43 -11 8 16 -15 11 -49
72 -68 123 -9 22 -11 18 -17 -30z"/>
<path d="M2493 5004 c-3 -9 -1 -24 5 -33 7 -9 20 -32 31 -51 35 -64 81 -89 81
-43 0 13 -97 143 -107 143 -2 0 -6 -7 -10 -16z"/>
<path d="M7987 5014 c-4 -4 -4 -10 -1 -15 6 -10 34 0 34 12 0 10 -24 12 -33 3z"/>
<path d="M2744 4975 c11 -26 13 -42 6 -49 -14 -14 -13 -49 3 -65 10 -11 8 -11
-10 -2 -25 13 -72 15 -58 3 8 -7 126 -42 228 -69 9 -2 17 0 17 5 0 5 -21 12
-47 16 -27 4 -61 13 -78 21 l-30 15 43 -3 c47 -3 52 2 21 21 -34 22 -15 24 46
7 31 -9 59 -14 62 -11 3 3 -24 19 -61 36 -36 17 -73 41 -83 54 -10 12 -31 31
-46 41 l-29 18 16 -38z"/>
<path d="M7860 4965 c-13 -14 -21 -27 -18 -30 2 -3 15 6 28 20 13 14 21 27 18
30 -2 3 -15 -6 -28 -20z"/>
<path d="M3417 4973 c-4 -3 -7 -20 -7 -37 0 -35 15 -25 25 17 7 25 -3 36 -18
20z"/>
<path d="M2696 4960 c-14 -40 -10 -70 9 -70 17 0 21 46 5 70 -8 13 -10 13 -14
0z"/>
<path d="M6740 4961 c0 -6 15 -21 33 -34 56 -42 63 -41 22 2 -39 41 -55 50
-55 32z"/>
<path d="M2941 4933 c7 -12 15 -20 18 -17 3 2 -3 12 -13 22 -17 16 -18 16 -5
-5z"/>
<path d="M3158 4938 c6 -6 18 -8 28 -6 14 3 12 5 -9 10 -19 3 -25 2 -19 -4z"/>
<path d="M7698 4933 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3361 4914 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4870 4915 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M3304 4892 c-13 -8 -34 -46 -34 -61 0 -21 21 -11 33 17 6 15 16 30
20 33 13 9 -7 19 -19 11z"/>
<path d="M4027 4873 c-4 -3 -7 -13 -7 -21 0 -12 3 -12 15 -2 8 7 15 16 15 21
0 11 -14 12 -23 2z"/>
<path d="M6835 4870 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7330 4842 c0 -12 -6 -33 -14 -45 -8 -13 -12 -35 -9 -54 4 -28 10
-33 32 -33 18 0 22 3 12 9 -9 6 -10 14 -3 27 14 28 26 123 12 104 -9 -13 -11
-13 -20 0 -8 12 -10 11 -10 -8z"/>
<path d="M3090 4839 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3316 4809 c-12 -39 -15 -69 -7 -69 4 0 11 17 14 37 6 37 1 58 -7 32z"/>
<path d="M7985 4799 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M6878 4784 c24 -17 39 -14 27 5 -3 6 -16 11 -28 11 l-21 0 22 -16z"/>
<path d="M3128 4773 c5 -10 8 -23 5 -29 -2 -6 33 -15 89 -23 51 -7 106 -15
122 -18 17 -3 26 -1 22 5 -3 5 -29 12 -58 16 -118 14 -143 18 -152 27 -6 6 -4
9 5 9 10 1 9 5 -5 15 -27 20 -39 19 -28 -2z"/>
<path d="M3036 4753 c39 -16 64 -16 64 1 0 11 -12 14 -47 13 -46 -1 -46 -1
-17 -14z"/>
<path d="M4991 4740 c13 -16 24 -28 27 -26 6 6 -29 56 -39 56 -5 0 0 -13 12
-30z"/>
<path d="M6833 4701 c3 -11 1 -24 -4 -27 -19 -11 -8 -24 20 -24 40 0 91 11 91
20 0 4 -21 16 -47 26 -27 11 -52 21 -57 23 -5 2 -7 -6 -3 -18z"/>
<path d="M2962 4562 c101 -81 210 -168 243 -193 70 -53 164 -117 150 -101 -10
10 -378 301 -463 365 -24 17 -56 42 -71 55 -16 12 -32 22 -36 22 -3 0 76 -67
177 -148z"/>
<path d="M7385 4700 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M7495 4700 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M3460 4661 c0 -5 9 -19 19 -32 l20 -24 -6 24 c-4 17 -3 22 5 17 7 -4
12 -13 12 -21 0 -8 4 -16 9 -20 6 -3 6 7 0 27 -5 18 -9 34 -9 35 0 2 -11 3
-25 3 -14 0 -25 -4 -25 -9z"/>
<path d="M4548 4663 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5060 4652 c0 -4 9 -15 20 -25 23 -19 26 -11 6 16 -14 18 -26 22 -26
9z"/>
<path d="M6709 4658 c-1 -2 -3 -25 -5 -52 -4 -43 -2 -48 13 -41 10 3 44 11 76
17 31 6 57 14 57 19 0 5 -29 9 -65 9 -63 0 -86 12 -45 23 28 7 25 27 -5 27
-14 0 -25 -1 -26 -2z"/>
<path d="M7038 4653 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4523 4633 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4765 4620 c27 -12 35 -12 35 0 0 6 -12 10 -27 9 -25 0 -26 -1 -8 -9z"/>
<path d="M7370 4615 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z"/>
<path d="M6636 4578 c-7 -18 -50 -89 -95 -157 -46 -68 -87 -135 -92 -149 -9
-24 -10 -24 -24 -6 -13 18 -14 17 -15 -16 0 -19 -3 -46 -7 -59 -10 -37 14 -22
39 26 27 50 193 299 219 328 12 13 16 29 12 43 -8 31 -20 27 -37 -10z"/>
<path d="M3215 4590 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3640 4590 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6213 4589 c14 -8 22 -8 30 0 8 8 3 11 -18 11 -26 -1 -28 -2 -12 -11z"/>
<path d="M6912 4593 c14 -2 38 -15 52 -28 24 -22 26 -23 26 -5 0 25 -30 40
-72 39 -29 -2 -30 -3 -6 -6z"/>
<path d="M7466 4540 c-42 -33 -76 -62 -76 -65 0 -18 96 43 139 89 53 55 23 43
-63 -24z"/>
<path d="M6384 4546 c-8 -22 3 -33 18 -18 9 9 9 15 0 24 -9 9 -13 7 -18 -6z"/>
<path d="M4410 4520 c33 -26 52 -25 21 0 -13 11 -28 20 -35 20 -6 0 0 -9 14
-20z"/>
<path d="M6460 4495 c0 -19 5 -35 10 -35 6 0 10 16 10 35 0 19 -4 35 -10 35
-5 0 -10 -16 -10 -35z"/>
<path d="M3647 4513 c-4 -3 -7 -15 -7 -25 0 -14 5 -17 23 -13 12 3 42 9 66 13
71 12 64 32 -11 32 -36 0 -68 -3 -71 -7z"/>
<path d="M3410 4460 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7495 4380 c-66 -49 -129 -96 -140 -105 -11 -9 -32 -24 -47 -33 -40
-23 -44 -42 -7 -32 31 9 68 37 222 168 84 72 106 93 95 92 -2 0 -57 -41 -123
-90z"/>
<path d="M3398 4433 c-22 -5 -23 -19 -2 -27 25 -9 34 -7 34 8 0 18 -10 24 -32
19z"/>
<path d="M4170 4434 c0 -24 362 -519 386 -528 8 -3 14 -12 14 -21 0 -8 5 -15
10 -15 20 0 9 29 -26 66 -20 22 -105 136 -189 254 -143 201 -195 266 -195 244z"/>
<path d="M3483 4343 c-21 -8 -15 -20 41 -79 50 -52 65 -91 30 -77 -8 3 -14 1
-14 -4 0 -14 49 -57 110 -96 30 -20 98 -70 150 -112 125 -100 148 -111 75 -35
-33 34 -55 61 -50 59 6 -3 55 -26 110 -52 55 -27 129 -58 165 -69 36 -12 76
-31 90 -43 65 -56 140 -92 180 -88 27 4 44 -2 77 -26 24 -17 46 -31 50 -31 4
0 17 -10 30 -22 13 -12 26 -19 29 -15 9 9 -128 137 -148 137 -9 0 -37 16 -62
35 -26 19 -51 35 -56 35 -6 0 -10 3 -10 8 0 4 -26 6 -57 4 -58 -3 -59 -3 -148
64 -170 127 -182 133 -263 137 -67 3 -76 6 -109 37 -21 21 -31 36 -23 38 6 2
17 -1 23 -7 13 -13 57 -15 57 -2 0 19 -49 78 -95 114 -130 101 -142 107 -182
90z"/>
<path d="M7146 4272 c-3 -5 -2 -22 2 -37 l8 -29 40 37 39 36 -42 1 c-23 0 -44
-4 -47 -8z"/>
<path d="M4485 4220 c-27 -30 -12 -34 15 -5 13 14 19 25 13 25 -5 0 -18 -9
-28 -20z"/>
<path d="M7276 4185 c-14 -11 -22 -12 -27 -4 -5 8 -8 8 -13 0 -4 -6 -1 -11 6
-11 7 -1 3 -9 -11 -21 -13 -11 -22 -22 -19 -24 3 -3 25 13 49 34 44 40 52 54
15 26z"/>
<path d="M6584 4157 c-45 -18 -88 -37 -95 -44 -19 -16 -109 -46 -117 -39 -8 9
-62 -45 -62 -61 0 -16 -5 -16 83 1 40 8 113 22 162 31 120 22 265 66 265 80 0
6 -19 23 -42 38 -54 35 -92 34 -194 -6z"/>
<path d="M3835 4170 c-8 -13 -1 -24 14 -19 6 3 7 10 2 18 -6 10 -10 10 -16 1z"/>
<path d="M3871 4163 c-1 -18 5 -23 22 -23 13 0 29 -6 35 -12 10 -10 15 -10 22
2 14 22 13 25 -22 38 -23 8 -34 9 -39 1 -4 -8 -8 -7 -12 2 -3 8 -6 4 -6 -8z"/>
<path d="M3777 4140 c-4 -17 0 -20 25 -20 30 0 31 0 13 20 -10 11 -21 20 -25
20 -4 0 -10 -9 -13 -20z"/>
<path d="M4080 4150 c8 -5 21 -10 28 -10 8 0 28 -7 46 -15 56 -27 206 -73 206
-63 0 5 -11 20 -25 33 -19 20 -34 25 -74 25 -27 0 -78 9 -113 20 -69 22 -91
25 -68 10z"/>
<path d="M4000 4102 c0 -21 36 -59 46 -49 10 9 -19 67 -33 67 -7 0 -13 -8 -13
-18z"/>
<path d="M6839 4042 c-92 -63 -228 -167 -229 -175 0 -5 10 -5 23 -1 12 4 31 8
42 10 22 3 204 83 293 130 7 3 -11 34 -21 34 -6 0 -20 9 -32 20 -21 19 -21 19
-76 -18z"/>
<path d="M7993 4036 l-42 -10 -3 -75 -3 -75 -140 -38 c-77 -21 -148 -37 -158
-34 -22 5 -32 22 186 -317 l57 -88 0 -264 c0 -170 -4 -265 -10 -265 -6 0 -10
94 -10 263 l0 262 -126 190 c-69 105 -137 201 -151 215 l-25 25 12 -25 c13
-24 12 -25 -7 -7 -11 9 -23 17 -27 17 -10 0 45 -95 124 -215 37 -55 83 -126
103 -158 l37 -57 0 -244 c0 -133 -2 -245 -5 -248 -6 -5 -100 16 -210 46 -33
10 -131 45 -217 79 l-158 61 -2 190 -3 191 -2 -180 c-2 -99 -7 -183 -12 -188
-9 -9 -21 337 -13 361 2 6 10 12 17 12 8 0 15 7 17 15 2 8 71 83 153 167 83
84 151 159 153 167 4 19 -41 -23 -283 -261 l-210 -208 -201 0 c-167 0 -204 -3
-214 -15 -11 -13 13 -15 206 -15 l219 0 38 31 37 30 0 -131 0 -132 -22 6
c-133 36 -347 66 -480 66 l-87 0 -4 33 -4 32 -1 -32 c-2 -34 -27 -47 -34 -19
-2 9 -35 -15 -92 -67 l-88 -82 132 3 c255 6 494 -37 762 -137 189 -70 264 -90
503 -136 86 -17 233 -17 522 -1 137 7 227 16 234 23 8 8 19 9 36 2 17 -6 30
-6 39 2 7 6 17 9 21 6 4 -2 17 -1 28 4 13 6 -2 7 -43 4 -48 -5 -62 -3 -60 7 2
10 -24 13 -117 14 -66 0 -144 1 -172 2 l-53 2 -1 308 c0 169 -4 316 -9 327 -5
12 -9 -116 -9 -308 -1 -216 -4 -326 -11 -322 -6 4 -10 122 -10 322 0 314 0
316 23 338 12 12 110 111 217 220 132 134 184 192 160 180 -19 -10 -121 -103
-227 -208 l-192 -190 -78 -8 c-120 -12 -163 -11 -163 5 0 15 68 26 167 26 l61
0 247 243 c136 133 250 243 254 244 3 2 85 -119 181 -267 96 -148 176 -266
178 -261 3 10 -347 551 -357 551 -4 0 -22 -14 -40 -31 -18 -16 -40 -28 -48
-25 -8 3 -12 2 -9 -3 9 -14 -42 -23 -64 -11 -24 13 -51 14 -31 1 10 -7 1 -8
-25 -4 -25 3 -75 -4 -139 -21 -55 -14 -102 -23 -105 -20 -6 6 -6 13 -8 73 -1
24 -5 42 -8 39 -3 -4 -18 -3 -32 1 -15 3 -46 2 -69 -3z m47 -877 l0 -311 -57
7 c-31 4 -62 9 -68 11 -9 4 -13 75 -14 275 -1 226 -4 274 -17 294 -15 23 -15
24 23 28 21 2 59 4 86 5 l47 2 0 -311z"/>
<path d="M4058 4010 c-4 -25 1 -37 21 -56 26 -24 49 -26 116 -6 l30 9 -25 14
c-14 8 -38 14 -55 14 -23 0 -31 5 -33 23 -2 12 -7 22 -12 22 -5 0 -15 3 -23 6
-10 4 -16 -4 -19 -26z"/>
<path d="M4290 3961 c0 -5 7 -11 15 -15 8 -3 15 -10 15 -16 0 -6 -6 -8 -12 -5
-7 4 -2 -3 12 -14 14 -11 29 -21 34 -21 5 0 26 -16 47 -35 52 -47 68 -45 30 4
-43 54 -141 126 -141 102z"/>
<path d="M6914 3949 c-19 -22 -19 -22 4 -8 12 8 22 17 22 21 0 12 -6 9 -26
-13z"/>
<path d="M6610 3940 c0 -12 17 -12 45 0 17 7 15 9 -12 9 -18 1 -33 -3 -33 -9z"/>
<path d="M7229 3873 c-37 -32 -166 -148 -289 -258 -122 -110 -230 -206 -241
-214 -10 -7 -19 -17 -19 -22 0 -5 80 -9 179 -9 l178 0 232 235 c127 129 231
241 231 248 0 7 -9 18 -19 24 -16 10 -33 8 -90 -7 -39 -11 -75 -20 -80 -20 -6
0 -11 18 -13 41 l-3 40 -66 -58z"/>
<path d="M6585 3910 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M8585 3908 c4 -11 2 -18 -4 -18 -6 0 -11 6 -11 13 0 8 -4 7 -10 -3
-8 -12 -10 -12 -10 -1 0 8 -3 12 -6 9 -6 -7 37 -77 195 -318 l121 -185 0 -249
0 -248 23 6 c12 3 28 6 35 6 9 0 12 56 12 246 l0 246 -116 177 c-64 97 -126
191 -137 209 -12 18 -28 30 -35 27 -9 -3 -11 1 -7 15 5 15 3 18 -10 14 -10 -4
-14 -13 -10 -23 5 -13 4 -14 -7 -3 -18 18 -19 65 -1 50 20 -17 15 1 -8 25 -15
17 -19 18 -14 5z"/>
<path d="M7520 3900 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3954 3865 c11 -8 25 -15 30 -15 6 0 2 7 -8 15 -11 8 -25 15 -30 15
-6 0 -2 -7 8 -15z"/>
<path d="M6800 3865 c-7 -8 -19 -15 -26 -15 -8 0 -14 -4 -14 -10 0 -18 26 -10
50 15 13 14 19 25 13 25 -6 0 -16 -7 -23 -15z"/>
<path d="M6909 3863 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M6284 3817 c-143 -74 -150 -84 -39 -60 91 21 177 87 129 99 -5 2 -45
-16 -90 -39z"/>
<path d="M6815 3785 c-22 -19 -67 -52 -100 -74 -33 -22 -80 -58 -104 -80 -24
-22 -47 -41 -51 -43 -5 -2 -46 -37 -91 -78 -74 -68 -146 -121 -371 -275 -307
-210 -373 -255 -383 -255 -5 0 -30 -19 -55 -41 l-45 -40 65 34 c66 35 405 246
515 320 64 43 66 45 416 343 201 172 259 224 248 224 -2 0 -22 -16 -44 -35z"/>
<path d="M6585 3799 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z"/>
<path d="M6779 3789 l-24 -20 28 17 c15 9 27 18 27 20 0 8 -8 4 -31 -17z"/>
<path d="M6689 3770 c-8 -5 -37 -16 -62 -25 -26 -9 -73 -32 -104 -51 -32 -20
-93 -57 -136 -84 -61 -37 -75 -49 -60 -54 27 -8 88 9 118 33 15 11 43 23 62
27 22 4 30 9 23 14 -12 8 29 38 142 104 27 15 51 32 54 37 7 12 -16 11 -37 -1z"/>
<path d="M4160 3730 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M6108 3733 c-23 -4 -48 -35 -148 -181 -188 -276 -201 -294 -214 -284
-6 5 -51 12 -101 16 -49 3 -134 14 -187 24 -94 18 -97 18 -87 0 6 -12 6 -18
-1 -18 -20 0 31 -40 53 -41 12 0 77 1 145 4 67 2 122 0 122 -5 0 -7 -103 -58
-116 -58 -3 0 -2 11 2 24 5 22 3 23 -33 19 -44 -4 -72 -34 -73 -75 0 -26 2
-27 33 -22 17 3 48 10 67 15 44 11 50 11 50 0 0 -5 -17 -12 -37 -16 -52 -9
-22 -26 34 -18 48 6 53 -1 25 -38 -10 -13 -31 -46 -46 -74 -16 -27 -38 -60
-49 -72 -36 -41 -3 -24 36 17 20 22 45 40 55 40 14 0 14 2 3 9 -11 7 -2 25 42
87 69 98 83 118 153 224 77 116 74 114 118 101 43 -11 70 -5 62 15 -4 9 12 23
44 39 35 18 50 31 50 45 0 27 -20 25 -83 -6 l-52 -25 40 35 c107 95 134 122
134 139 1 20 -29 23 -61 6 -11 -6 -22 -8 -25 -5 -6 6 42 55 72 73 21 12 18 13
-27 6z m-157 -270 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z
m-258 -294 c-6 -6 -17 -8 -24 -3 -9 5 -8 9 7 14 23 9 31 3 17 -11z"/>
<path d="M6405 3700 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M6170 3664 c0 -14 3 -14 15 -4 8 7 15 14 15 16 0 2 -7 4 -15 4 -8 0
-15 -7 -15 -16z"/>
<path d="M4295 3652 c28 -28 154 -112 168 -112 7 0 7 3 2 8 -61 53 -211 144
-170 104z"/>
<path d="M6304 3655 c-19 -14 -19 -14 1 -14 11 0 29 6 39 14 20 15 20 15 0 15
-11 0 -29 -7 -40 -15z"/>
<path d="M6558 3633 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6208 3594 c-27 -14 -54 -51 -45 -61 6 -6 96 59 97 70 0 11 -22 8
-52 -9z"/>
<path d="M8405 3551 c-3 -2 -5 -24 -5 -48 l0 -43 40 0 40 0 0 44 c0 43 -1 44
-35 48 -20 2 -38 2 -40 -1z"/>
<path d="M8502 3503 c3 -48 3 -48 41 -51 l38 -3 -3 48 c-3 48 -3 48 -41 51
l-38 3 3 -48z"/>
<path d="M6349 3520 c-13 -10 -20 -19 -16 -20 11 0 67 29 67 35 0 12 -30 3
-51 -15z"/>
<path d="M7399 3506 c-2 -3 -3 -23 -1 -45 4 -40 5 -41 43 -41 39 0 39 0 39 40
0 31 -4 40 -17 40 -10 0 -27 3 -38 6 -11 3 -23 3 -26 0z"/>
<path d="M7510 3460 l0 -40 43 0 c42 0 44 1 41 28 -1 15 -2 33 -3 40 -1 7 -16
12 -41 12 l-40 0 0 -40z"/>
<path d="M8400 3390 l0 -50 40 0 40 0 0 50 0 50 -40 0 -40 0 0 -50z"/>
<path d="M8500 3391 c0 -54 6 -59 58 -45 20 6 23 12 20 48 -3 40 -4 41 -40 44
l-38 3 0 -50z"/>
<path d="M6224 3421 c-46 -8 -118 -47 -153 -85 -15 -16 -35 -26 -51 -26 -15 0
-39 -9 -53 -20 -14 -11 -28 -20 -32 -20 -3 0 -12 -6 -20 -12 -7 -7 -28 -24
-47 -38 -48 -36 -85 -76 -91 -100 -7 -27 -2 -25 41 10 163 133 192 150 267
164 39 8 61 18 120 56 17 11 47 29 68 40 21 11 35 25 32 30 -7 11 -19 11 -81
1z"/>
<path d="M4770 3405 c7 -8 16 -12 21 -9 13 8 1 24 -18 24 -13 0 -13 -3 -3 -15z"/>
<path d="M7400 3360 c0 -53 6 -58 58 -44 18 5 22 13 22 44 0 31 -4 39 -22 44
-52 14 -58 9 -58 -44z"/>
<path d="M7510 3356 c0 -39 2 -43 23 -41 12 1 32 3 43 4 21 1 24 15 15 74 -1
4 -19 7 -41 7 l-40 0 0 -44z"/>
<path d="M5100 3299 c0 -14 55 -69 70 -69 6 0 -6 18 -26 40 -40 43 -44 46 -44
29z"/>
<path d="M5398 3158 c9 -9 15 -9 24 0 9 9 7 12 -12 12 -19 0 -21 -3 -12 -12z"/>
<path d="M6535 2964 c108 -19 262 -65 425 -126 284 -107 288 -108 380 -107 83
1 233 14 197 17 -28 3 -250 69 -309 93 -214 86 -477 140 -670 138 l-103 -1 80
-14z"/>
<path d="M8588 2843 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5633 2793 c-4 -10 -14 -23 -22 -30 -9 -7 -11 -13 -5 -13 18 0 57 47
45 55 -6 3 -14 -2 -18 -12z"/>
<path d="M2120 2614 c-61 -12 -124 -38 -155 -65 -55 -49 -68 -95 -73 -264 -8
-279 34 -356 213 -390 89 -17 300 -20 395 -5 95 15 165 52 195 103 34 59 49
167 42 315 -9 183 -43 245 -159 289 -33 13 -86 17 -238 19 -107 1 -206 0 -220
-2z m332 -192 c21 -21 23 -33 26 -155 3 -111 0 -137 -15 -167 -21 -41 -41 -48
-149 -49 -96 -1 -142 12 -155 46 -12 32 -12 275 1 308 14 37 43 45 162 42 97
-2 110 -4 130 -25z"/>
<path d="M6540 2614 c-56 -11 -122 -37 -149 -60 -16 -13 -39 -47 -52 -76 -22
-49 -24 -65 -24 -229 0 -175 0 -175 30 -234 54 -104 144 -134 395 -135 151 0
237 13 300 45 98 50 124 127 118 354 -6 214 -31 267 -147 313 -50 19 -78 22
-251 24 -107 1 -206 0 -220 -2z m313 -175 c39 -18 47 -50 47 -194 0 -127 -1
-135 -24 -162 -24 -28 -26 -28 -140 -28 -159 0 -160 1 -164 173 -4 149 6 196
46 210 37 14 206 15 235 1z"/>
<path d="M8915 2606 c-90 -22 -134 -55 -171 -126 -27 -52 -33 -343 -9 -427 22
-75 73 -126 153 -150 84 -27 421 -26 507 0 72 22 134 81 153 144 18 60 16 359
-3 412 -45 128 -131 161 -410 160 -99 0 -191 -6 -220 -13z m340 -166 c40 -15
55 -64 55 -182 0 -136 -11 -175 -55 -194 -45 -19 -185 -18 -230 0 -44 19 -49
40 -49 196 0 133 8 164 47 180 30 12 200 12 232 0z"/>
<path d="M2940 2250 l0 -360 120 0 120 0 2 265 3 265 145 -265 145 -265 187 0
188 0 0 360 0 360 -120 0 -120 0 -2 -265 -3 -266 -145 265 -145 266 -187 0
-188 0 0 -360z"/>
<path d="M4090 2250 l0 -360 125 0 125 0 0 360 0 360 -125 0 -125 0 0 -360z"/>
<path d="M4580 2250 l0 -360 340 0 340 0 0 85 0 85 -210 0 -210 0 0 275 0 275
-130 0 -130 0 0 -360z"/>
<path d="M5410 2250 l0 -360 360 0 360 0 0 80 0 80 -235 0 -236 0 3 68 3 67
223 3 223 2 -3 68 -3 67 -222 3 -223 2 0 60 0 60 230 0 230 0 0 80 0 80 -355
0 -355 0 0 -360z"/>
<path d="M7310 2602 c0 -4 32 -165 70 -357 39 -192 70 -350 70 -352 0 -2 82
-3 183 -3 l183 0 58 241 c32 132 60 238 62 236 5 -4 20 -69 84 -340 l32 -137
181 2 180 3 49 225 c27 124 61 285 77 358 l28 133 -126 -3 -126 -3 -43 -240
c-23 -132 -46 -253 -50 -270 -7 -25 -19 14 -71 230 -34 143 -64 266 -67 273
-3 10 -37 12 -146 10 l-142 -3 -70 -267 c-39 -147 -74 -262 -77 -255 -4 7 -10
35 -13 62 -10 70 -66 415 -71 443 -5 21 -9 22 -130 22 -69 0 -125 -4 -125 -8z"/>
<path d="M9745 1781 c-147 -104 -298 -194 -490 -291 -543 -272 -1084 -417
-1805 -482 -184 -16 -878 -16 -1090 0 -543 43 -1092 117 -1745 238 -195 35
-966 185 -1065 206 l-55 12 68 6 c74 7 101 22 151 84 27 33 31 45 31 102 0 59
-3 66 -33 96 -32 32 -35 33 -131 36 l-99 4 -37 -154 c-20 -84 -38 -154 -39
-156 -2 -1 -59 9 -127 23 -348 71 -924 170 -1222 210 -347 46 -819 85 -1038
85 l-49 0 2 -322 3 -322 45 1 c25 0 200 4 390 8 437 9 788 -4 1450 -55 127
-10 611 -56 1070 -101 1337 -131 1972 -167 2740 -156 593 8 1031 53 1465 148
628 138 1124 362 1535 694 100 81 173 147 159 144 -2 0 -40 -26 -84 -58z
m-6099 -31 c39 -15 53 -39 54 -89 0 -53 -34 -117 -73 -138 -16 -8 -60 -17 -99
-20 -66 -6 -70 -5 -65 13 3 10 18 70 32 132 l27 112 48 0 c27 0 61 -5 76 -10z"/>
<path d="M4856 1785 c-147 -52 -173 -248 -40 -307 81 -35 172 -9 230 68 25 33
29 46 29 105 0 61 -3 71 -29 98 -47 49 -117 62 -190 36z m133 -41 c64 -45 58
-147 -13 -213 -34 -32 -115 -41 -152 -16 -39 25 -55 71 -43 125 11 50 21 65
63 98 35 27 110 30 145 6z"/>
<path d="M7457 1785 c-102 -36 -155 -158 -111 -251 41 -86 184 -94 260 -16 19
19 34 41 34 49 0 25 -33 13 -64 -24 -28 -31 -39 -37 -87 -41 -49 -4 -57 -1
-82 23 -48 48 -35 146 27 204 49 46 176 36 176 -14 0 -9 10 -15 25 -15 29 0
30 2 10 39 -16 31 -77 61 -123 61 -15 -1 -44 -7 -65 -15z"/>
<path d="M7848 1791 c-88 -29 -138 -95 -138 -183 0 -50 4 -62 30 -92 81 -91
250 -57 302 62 36 80 10 168 -57 201 -36 17 -102 23 -137 12z m133 -60 c23
-24 29 -38 29 -73 0 -127 -136 -206 -222 -129 -29 26 -33 35 -32 79 1 57 31
114 72 137 15 8 49 14 75 15 41 0 53 -5 78 -29z"/>
<path d="M3827 1637 c-20 -84 -37 -156 -37 -160 0 -4 10 -7 23 -7 21 0 25 10
55 138 18 75 35 147 38 160 5 18 2 22 -18 22 -23 0 -26 -8 -61 -153z"/>
<path d="M4013 1658 c-47 -73 -94 -145 -105 -160 l-19 -28 28 0 c22 0 34 9 56
45 l29 45 78 0 79 0 7 -45 c5 -40 9 -45 31 -45 21 0 24 4 20 23 -3 12 -15 74
-27 137 -31 171 -26 160 -61 160 -27 0 -37 -11 -116 -132z m121 30 c4 -29 10
-63 13 -75 5 -22 2 -23 -56 -23 -34 0 -61 3 -61 6 0 11 84 144 91 144 4 0 10
-24 13 -52z"/>
<path d="M4316 1642 c-20 -82 -36 -155 -36 -161 0 -7 10 -11 21 -9 19 3 25 18
45 103 13 55 27 111 30 125 5 17 13 -13 28 -100 20 -119 22 -125 46 -128 22
-2 35 12 105 122 44 68 81 123 83 121 1 -1 -11 -53 -28 -116 -16 -63 -30 -117
-30 -121 0 -5 10 -8 23 -8 20 0 25 11 55 138 18 75 35 147 38 160 6 19 3 22
-25 22 -29 0 -37 -8 -110 -122 -44 -68 -86 -132 -95 -143 -14 -19 -15 -18 -16
20 -1 22 -9 85 -18 140 l-17 100 -32 3 -31 3 -36 -149z"/>
<path d="M5152 1652 c-18 -75 -35 -147 -38 -159 -5 -19 -2 -23 18 -23 22 0 26
7 47 98 13 53 27 114 33 136 l9 38 62 -133 c55 -118 66 -134 89 -137 30 -4 25
-15 67 161 36 151 37 157 11 157 -21 0 -22 -4 -61 -169 l-21 -94 -63 132 c-59
122 -66 131 -92 131 -28 0 -28 -1 -61 -138z"/>
<path d="M5526 1640 c-20 -83 -36 -155 -36 -160 0 -16 186 -12 224 4 126 53
158 239 50 291 -21 10 -62 15 -117 15 l-85 0 -36 -150z m200 110 c39 -15 53
-39 54 -88 0 -54 -29 -112 -69 -135 -33 -19 -155 -35 -163 -21 -2 5 9 64 25
132 l29 122 48 0 c27 0 61 -5 76 -10z"/>
<path d="M6050 1775 c0 -12 13 -15 61 -15 l62 0 -6 -27 c-4 -16 -19 -81 -35
-145 l-29 -118 23 0 c18 0 25 8 33 38 11 42 46 183 55 224 6 25 10 27 68 30
38 2 63 8 66 16 3 9 -31 12 -147 12 -128 0 -151 -2 -151 -15z"/>
<path d="M6391 1753 c-6 -21 -23 -93 -39 -160 l-29 -123 143 0 c121 0 144 2
144 15 0 12 -19 15 -115 15 l-116 0 7 38 c15 84 11 81 130 84 75 2 109 7 112
16 3 9 -22 12 -102 12 -118 0 -116 -2 -96 72 l11 37 111 3 c78 2 113 7 116 16
3 9 -28 12 -132 12 l-135 0 -10 -37z"/>
<path d="M6745 1778 c-4 -12 -55 -217 -69 -280 l-6 -28 119 0 c96 0 120 3 125
15 4 13 -9 15 -89 15 -64 0 -95 4 -95 11 0 6 14 67 30 136 17 68 30 128 30
133 0 15 -40 12 -45 -2z"/>
<path d="M7035 1778 c-6 -18 -75 -296 -75 -303 0 -3 64 -4 142 -3 102 2 143 6
146 16 3 9 -24 12 -112 12 -130 0 -125 -4 -105 76 l11 44 108 0 c87 0 109 3
114 15 5 13 -11 15 -104 15 l-109 0 6 33 c15 80 11 77 133 77 91 0 110 3 110
15 0 13 -21 15 -130 15 -96 0 -132 -3 -135 -12z"/>
<path d="M8167 1783 c-3 -5 -19 -69 -36 -143 -18 -74 -35 -143 -37 -152 -4
-14 1 -18 19 -18 23 0 26 7 51 113 14 61 26 118 26 126 0 38 12 -6 25 -90 24
-151 24 -149 51 -149 20 0 38 20 101 118 41 64 79 120 83 125 3 4 -8 -49 -26
-118 l-32 -125 23 0 c27 0 26 -3 69 180 13 58 28 113 31 123 6 14 1 17 -26 17
-33 0 -38 -6 -114 -125 -44 -69 -85 -133 -91 -142 -9 -12 -13 1 -23 63 -33
214 -30 204 -61 204 -16 0 -31 -3 -33 -7z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
